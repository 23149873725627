import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/errorBoundary';
import Header from './components/Header';
import MainContent from './views/main_content';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <React.Fragment>
          <Header />
          <Routes>
            <Route path="/" element={<MainContent/>} />
          </Routes>
        </React.Fragment>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
