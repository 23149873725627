import React, { useState, useEffect } from 'react';
import { format, parse, isBefore, isEqual } from 'date-fns';
import Select from '../components/select';
import Graphic from '../components/graphic';
import Card from '../components/card';

const disciplinas = [
    ["Futbol", 0],
    ["Futbol Sala", 1]
];

const categoriasFutbol = [
    ["Campionat de Catalunya Juvenil", 50554257],
    ["Campionat de Catalunya Cadet", 50554279],
    ["Campionat de Catalunya Infantil", 50554303],
    ["Campionat de Catalunya Aleví S12", 50554322],
    ["Campionat de Catalunya Aleví S11", 50554340],
    ["Campionat de Catalunya Benjamí S10", 50554356],
    ["Campionat de Catalunya Benjamí S9", 50554372],
    ["Campionat de Catalunya Femení Juvenil", 50554392],
    ["Campionat de Catalunya Femení Cadet F7", 50567486],
    ["Campionat de Catalunya Femení Cadet F11", 50554401],
    ["Campionat de Catalunya Femení Infantil", 50554408],
    ["Campionat de Catalunya Femení Aleví", 50554416],
    ["Campionat de Catalunya Femení Benjamí", 50554427]
];

const femenino = [50554392, 50554401, 50567486, 50554408, 50554416, 50554427];

const categoriasFutbolSala = [
    ["FINALS", 0]
];

const catFutbolSala = [
    ["COPA CATALUNYA BENJAMÍ FUTBOL SALA", 49773739],
    ["COPA CATALUNYA MINIBENJAMÍ FUTBOL SALA", 50390827],
    ["COPA CATALUNYA INFANTIL FEMENÍ FUTBOL SALA", 49774053],
    ["COPA CATALUNYA ALEVÍ FEMENÍ FUTBOL SALA", 49774122],
    ["COPA CATALUNYA BENJAMÍ FEMENÍ FUTBOL SALA", 49774162],
    ["COPA CATALUNYA CADET FEMENÍ FUTBOL SALA", 49774018],
    ["COPA CATALUNYA JUVENIL FEMENÍ FUTBOL SALA", 49773961],
    ["COPA CATALUNYA PREBENJAMÍ FUTBOL SALA", 50390892],
    ["COPA CATALUNYA MINIPREBENJAMÍ FUTBOL SALA", 50390784],
    ["COPA CATALUNYA ALEVÍ FUTBOL SALA", 49773485],
    ["COPA CATALUNYA INFANTIL FUTBOL SALA", 49772420],
    ["COPA CATALUNYA CADET FUTBOL SALA", 49772172],
    ["COPA CATALUNYA JUVENIL FUTBOL SALA", 49771921]
];

function MainContent() {
    //-----------------Gestión formularios--------------------
    const [disciplina, setDisciplina] = useState('');
    const [categoria, setCategoria] = useState('');
    const [semifinals, setSemifinals] = useState([]);
    const [final, setFinal] = useState([]);
    const [campio, setCampio] = useState([]);
    const [futbolSala, setFutbolSala] = useState([]);
    const today = new Date();

    const apiCall = () => {
        // Inicializamos el método
        const requestOptions = {
            method: 'GET'
        };

        if (disciplina === 0) {
            // Realizamos ambas peticiones fetch en paralelo
            let val1 = 2;
            let val2 = 3;
            if(femenino.includes(categoria)){
                val1 = 1;
                val2 = 2;
            }
            Promise.all([
                fetch(`https://apidev.fcf.cat/competition/games/${categoria}/${val1}`, requestOptions)
                    .then(response => response.json())
                    .then(data => data.result)
                    .catch(error => {
                        console.log('Error en la petición API semifinals Futbol ' + error);
                        return [];
                    }),
                fetch(`https://apidev.fcf.cat/competition/games/${categoria}/${val2}`, requestOptions)
                    .then(response => response.json())
                    .then(data => data.result)
                    .catch(error => {
                        console.log('Error en la petición API finals Futbol ' + error);
                        return [];
                    })
            ]).then(([semifinalsData, finalsData]) => {
                setSemifinals(semifinalsData);
                setFinal(finalsData);
                setFutbolSala([]);
                calcularGanador(finalsData);
            });
        } else if (disciplina === 1) {
            // Realizamos todas las peticiones fetch en paralelo
            const fetchPromises = catFutbolSala.map(category =>
                fetch(`https://apidev.fcf.cat/competition/games/${category[1]}`, requestOptions)
                    .then(response => response.json())
                    .then(data => data.result)
                    .catch(error => {
                        console.log(`Error en la petición API ${category[0]}: ` + error);
                        return [];
                    })
            );

            Promise.all(fetchPromises).then(results => {
                const combinedData = results.flat();
                setFutbolSala(combinedData);
                setSemifinals([]);
            });
        }
    };

    const calcularGanador = (finals) => {
        if (finals && finals.length > 0) {
            const match = finals[0];
            if (match.FECHA !== '') {
                const itemDate = parse(match.FECHA, 'yyyy-MM-dd HH:mm:ss', new Date());
                const calcularCampio = isBefore(itemDate, today) || isEqual(itemDate, today);

                if (calcularCampio) {
                    if (match.GOLES_FUERA > match.GOLES_CASA) {
                        setCampio([
                            match.NOMBRE_EQUIPO_VISITANTE,
                            match.ESCUDO_VISITANTE
                        ])
                    } else {
                        setCampio([
                            match.NOMBRE_EQUIPO_LOCAL,
                            match.ESCUDO_LOCAL
                        ])
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (disciplina !== '' && categoria !== '') {
            apiCall();
        }
    }, [disciplina, categoria]);

    return (
        <React.Fragment>
            <div id="contenedor" className='w-full mx-auto bg-white'>
                <div id="tarjeta" className="py-12 sm:w-full md:w-2/3 lg:2/4 xl:2/4 2xl:2/4 mx-auto md:mt-14 mt-0 md:mb-10 mb-0 rounded bg-background shadow-md overflow-hidden">
                    <div className='flex flex-col md:flex-row items-center justify-center'>
                        <Select
                            texto="Disciplina"
                            opciones={disciplinas}
                            setValue={(value) => setDisciplina(Number(value))}
                            disabled={false}
                        />
                        <Select
                            texto="Categoria"
                            opciones={disciplina === 0 ? categoriasFutbol : categoriasFutbolSala}
                            setValue={(value) => setCategoria(Number(value))}
                            disabled={disciplina === ''}
                        />
                    </div>
                    <div className='pt-6'>
                        {
                            (semifinals.length > 0) &&
                            <Graphic semifinals={semifinals} final={final} campio={campio} />
                        }
                        {
                            (futbolSala.length > 0) &&
                            futbolSala.map((item, index) => (
                                <div key={index} className="flex flex-col m-4 items-center justify-center">
                                    <h2 className="font-semibold text-center mt-4">{catFutbolSala[index][0]}</h2>
                                    <Card item={item} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MainContent;