import React from 'react';
import { format, parse, isBefore, isEqual } from 'date-fns';
import { HiPencilAlt } from "react-icons/hi";

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const convertDateFormat = (dateString) => {
    const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    return format(parsedDate, 'dd-MM-yyyy HH:mm');
};

const Card = ({ item }) => {

  const today = new Date();
  const itemDate = parse(item.FECHA, 'yyyy-MM-dd HH:mm:ss', new Date());
  const shouldShowScores = isBefore(itemDate, today) || isEqual(itemDate, today);

  return (
    <React.Fragment>
      <div id="tarjeta" className='bg-white m-4 rounded-xl overflow-hidden w-64 flex flex-col'>
        <div id="equipos" className='flex flex-row p-4 w-full h-32'>
            <div id="local" className='w-1/2 flex flex-col items-center justify-center h-full mr-2'>
                {
                    item.ESCUDO_LOCAL ? (
                        <img src={`https://files.fcf.cat/escudos/clubes/escudos/${item.ESCUDO_LOCAL}`} alt="escut_local" className='w-12 min-h-1/2 h-1/2 max-h-1/2'/>
                    ) : (
                        <div className="bg-gray-100 w-12 min-h-1/2 h-1/2 max-h-1/2 rounded-full"></div>
                    )
                }
                {
                    item.NOMBRE_EQUIPO_LOCAL ? (
                        <h3 className='text-xs font-semibold text-center h-1/4 min-h-1/4 max-h-1/4'>{truncateText(item.NOMBRE_EQUIPO_LOCAL, 20)}</h3>
                    ) : (
                        <div className="bg-gray-100 min-h-1/4 h-1/4 max-h-1/4 w-20 m-1 rounded"></div>
                    )
                }
                {
                    shouldShowScores && item.GOLES_CASA && item.ESCUDO_LOCAL ? (
                        <p className='font-bold h-1/4 min-h-1/4 max-h-1/4 p-1 m-1'>{item.GOLES_CASA}</p>
                    ) : (
                        <div className="bg-gray-100 min-h-1/4 h-1/4 max-h-1/4 w-8 mt-2 p-3 rounded"></div>
                    )
                }
            </div>
            <div id="visitant" className='w-1/2 flex flex-col items-center justify-center h-full'>
                {
                    item.ESCUDO_VISITANTE ? (
                        <img src={`https://files.fcf.cat/escudos/clubes/escudos/${item.ESCUDO_VISITANTE}`} alt="escut_visitant" className='w-12 min-h-1/2 h-1/2 max-h-1/2'/>
                    ) : (
                        <div className="bg-gray-100 w-12 min-h-1/2 h-1/2 max-h-1/2 rounded-full"></div>
                    )
                }
                {
                    item.NOMBRE_EQUIPO_VISITANTE ? (
                        <h3 className='text-xs font-semibold text-center h-1/4 min-h-1/4 max-h-1/4'>{truncateText(item.NOMBRE_EQUIPO_VISITANTE, 20)}</h3>
                    ) : (
                        <div className="bg-gray-100 min-h-1/4 h-1/4 max-h-1/4 w-20 m-1 rounded"></div>
                    )
                }
                {
                    shouldShowScores && item.GOLES_FUERA && item.ESCUDO_VISITANTE ? (
                        <p className='font-bold h-1/4 min-h-1/4 max-h-1/4 p-1 m-1'>{item.GOLES_FUERA}</p>
                    ) : (
                        <div className="bg-gray-100 min-h-1/4 h-1/4 max-h-1/4 mt-2 p-3 w-8 rounded"></div>
                    )
                }
            </div>
        </div>
        
            {
                (item.NOMBRE_EQUIPO_LOCAL && item.NOMBRE_EQUIPO_VISITANTE) ? (
                    <div className='bg-gray-200 p-2 flex flex-row items-center justify-center text-xs'>
                        <p className=' mr-2'>{convertDateFormat(item.FECHA)}</p>
                        <a href={`https://fcf.cat/acta/${item.CODACTA}`} target='_blank'><HiPencilAlt className='hover:text-gray-500 cursor-pointer mr-1'/></a>
                    </div>
                ) : (
                    <></>
                )
            }
       
      </div>
    </React.Fragment>
  );
}

export default Card;
