import React from 'react';
import { IoMdFootball, IoIosRibbon  } from "react-icons/io";

const Select = ({texto, opciones, setValue, disabled}) => {

    return (
        <React.Fragment>
            <div id={texto} className="my-2 w-80 md:w-96 justify-center flex-row items-center rounded-md mr-4">
                <div className='flex items-center w-full'>
                    <div>
                        <div className="flex items-center bg-gray-200 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                            {
                                texto === "Disciplina" ? <IoMdFootball  /> : <IoIosRibbon />
                            }
                        </div>
                    </div>

                    <div className="w-full">
                        <select 
                            required 
                            defaultValue=""
                            onChange={(e) => setValue(e.target.value)}
                            disabled={disabled}
                            className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-200 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-200`}
                        >
                            <option value="" disabled hidden>{texto}</option>
                            {
                                opciones.map((opcion,index) => (
                                    <option key={index} value={opcion[1]}>{opcion[0]}</option>
                                ))
                            }
                        </select>                                
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Select;