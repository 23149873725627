import React from 'react';

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const Campio = ({ campio }) => {

    console.log(campio);

  return (
    <React.Fragment>
      <div id="tarjeta" className='bg-white m-4 rounded-xl overflow-hidden w-64 flex flex-col items-center p-3 ring ring-gray-300'>
        {
            campio.length > 0 ? (
                <img src={`https://files.fcf.cat/escudos/clubes/escudos/${campio[1]}`} alt="escut_campio" className='w-12 h-1/2'/>
            ) : (
                <div className="bg-gray-100 w-12 min-h-1/2 h-1/2 max-h-1/2 p-6 rounded-full"></div>
            )
        }
        {
            campio.length > 0 ? (
                <h3 className='text-xs font-semibold text-center mt-1'>{campio[0] && truncateText(campio[0], 26)}</h3>
            ) : (
                <div className="bg-gray-100 min-h-1/4 h-1/4 max-h-1/4 w-20 m-1 p-3 rounded"></div>
            )
        }
      </div>
    </React.Fragment>
  );
}

export default Campio;
