import React from 'react';
import { format, parse, isBefore, isEqual } from 'date-fns';
import Card from './card';
import Campio from './campio';
import { HiOutlineNewspaper, HiOutlinePhotograph, HiOutlineVideoCamera } from "react-icons/hi";
import { FaChevronCircleRight } from "react-icons/fa";
import { IoMdTrophy } from "react-icons/io";


const Graphic = ({ semifinals, final, campio }) => {
 
    console.log(semifinals);
    console.log(final);
    console.log(campio);

    const convertDateFormat = (dateString) => {
        const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
        return format(parsedDate, 'dd-MM-yyyy HH:mm');
    };

  const today = new Date();

  return (
    <React.Fragment>
        <div className='flex flex-col md:flex-row justify-center items-center'>
            {
                semifinals.length > 0 && (
                    <div id="semifinals" className='mt-4 w-1/3 flex flex-col items-center'>
                        <h2 className='font-semibold text-center uppercase'>Semifinals</h2>
                        {
                            semifinals.map((item, index) => (
                                <div className='flex items-center justify-center '>
                                    <Card key={index} item={item}/>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                final.length > 0 && (
                    <div className='hidden md:flex items-center justify-center w-12 h-full'> {/* Contenedor para centrar el icono */}
                        <FaChevronCircleRight className='text-3xl'/> {/* Ajusta el tamaño del icono si es necesario */}
                    </div>
                )
            }
            {
                final.length > 0 && (
                    <div id="finals" className='mt-4 md:mt-0 w-1/3 flex flex-col items-center'>
                        <h2 className='font-semibold text-center uppercase'>Final</h2>                
                        <div className='flex flex-col items-center justify-center h-full'>
                            <Card item={final[0]}/>
                            {/*<div className='flex flex-row justify-center'>
                                <HiOutlineNewspaper className='hover:text-gray-500 cursor-pointer mr-1'/>
                                <HiOutlinePhotograph className='hover:text-gray-500 cursor-pointer mr-1'/>
                                <HiOutlineVideoCamera className='hover:text-gray-500 cursor-pointer mr-1'/>
                </div>*/}
                        </div>
                    </div>
                )
            }
            {
                semifinals.length > 0 && final.length > 0 && campio && (
                    <div className='hidden md:flex items-center justify-center w-12 h-full'> {/* Contenedor para centrar el icono */}
                        <FaChevronCircleRight className='text-3xl'/> {/* Ajusta el tamaño del icono si es necesario */}
                    </div>
                )
            }            
            {
                semifinals.length > 0 && final.length > 0 && campio && (
                    <div id="campio" className='mt-4 md:mt-0 w-1/3 flex flex-col items-center'>                        
                        <h2 className='font-semibold text-center mt-3 uppercase'>Campió</h2>
                        <IoMdTrophy className='text-4xl text-yellow-500'/>
                        <Campio campio={campio}/>
                    </div>
                )
            }
            
        </div>
    </React.Fragment>
  );
}

export default Graphic;
