import React from 'react';
import logo from '../assets/img/logobn-png-h-no-texto.png';
const Header = () => {

    return (
        <React.Fragment>
            <header>
                <div className='bg-customRed h-20 flex items-center justify-center top-0 w-full z-50'>
                    <img src={logo} className='p-1 pl-1 pb-3 pt-3 h-full invert'/>
                    <h1 className='text-white font-bebas text-3xl md:text-4xl ml-2 md:ml-5 tracking-wide'>13<span className='font-roboto'>a</span> festa del futbol català</h1>      
                </div>
            </header>
        </React.Fragment>
    );
}

export default Header;